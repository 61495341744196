import moment from "moment"

const initialExpirationDate = moment().add(3, "M")

const modalInitialValues = {
  isCard: false,
  isFullheight: false,
  isActive: false,
  content: null,
  heading: "",
  headerClass: null,
  hideCloseButton: false,
  background: {
    color: null,
    opacity: "100",
  },
  disableCloseModal: false,
}

const eprescriptionInitialValues = {
  medicines: [],
  indication: {
    label: "Type 2 Diabetes",
    value: "Type 2 Diabetes",
  },
  expirationDate: {
    month: {
      label: initialExpirationDate.format("MMM"),
      value: initialExpirationDate.format("MMM"),
    },
    date: {
      label: parseInt(initialExpirationDate.format("D")),
      value: parseInt(initialExpirationDate.format("D")),
    },
    year: initialExpirationDate.year(),
  },
  patientInstructions:
    "0.25mg once a week for four weeks\n0.5mg once a week for eight weeks",
  otherIndication: "",
  concurrentMedication: [],
}

const patientInitialValues = {}

const doctorEnrollmentInitialValues = {
  firstName: "",
  lastName: "",
  middleInitial: "",
  email: "",
  mobileNumber: "",
  ptrNumber: "",
  prcNumber: "",
  prcId: {
    front: {},
    back: {},
  },
  consentToBeEngaged: [],
  specialization: {
    value: null,
  },
  otherSpecialization: "",
  contactThrough: ["SMS", "Email", "Call"],
  primaryClinic: {
    name: {
      value: null,
    },
    otherHospital: "",
    clinicHours: "",
    clinicContactNumber: "",
    addressType: "",
    streetAddress: "",
    province: {
      value: "Metro Manila",
      label: "Metro Manila",
    },
    city: {
      value: null,
    },
  },
  isClinicDeliveryAddress: [
    "Your clinic address is also your delivery address",
  ],
  deliveryAddress: {
    addressType: "",
    streetAddress: "",
    province: {
      value: "Metro Manila",
      label: "Metro Manila",
    },
    city: {
      value: null,
    },
  },
}

const consentAndAuthInitialValue = []

const documentsInitialValue = []

const epharmacyInitialValues = {
  deliveryAddress: {
    addressType: "",
    streetAddress: "",
    province: {
      value: "Metro Manila",
      label: "Metro Manila",
    },
    city: {
      value: null,
    },
    barangay: {
      value: null,
    },
  },
  prescribingDoctor: "",
  patientClinic: {
    name: {
      value: null,
    },
    otherHospital: "",
  },
  authorizedPerson: {
    fullName: "",
    contactNumber: "",
  },
  continueReceivingInformation: [],
  contactThrough: ["SMS", "Email", "Call"],
  otherDiabetesMedications: "",
  patient: {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    birthday: {
      month: {
        value: null,
      },
      date: {
        value: null,
      },
      year: "",
    },
  },
}

const toastInitialValues = {
  isActive: false,
  message: null,
  color: null,
}

export const initialState = {
  modal: { ...modalInitialValues },
  eprescription: { ...eprescriptionInitialValues },
  patient: { ...patientInitialValues },
  doctorEnrollment: { ...doctorEnrollmentInitialValues },
  consentAndAuth: consentAndAuthInitialValue,
  documents: documentsInitialValue,
  epharmacy: { ...epharmacyInitialValues },
  toast: { ...toastInitialValues },
}
