import { initialState } from "./utils/initialState"

export const modal = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
}

export const eprescription = {
  SAVE_MEDICINES: "SAVE_MEDICINES",
  SAVE_EPRESCRIPTION: "SAVE_EPRESCRIPTION",
  RESET_EPRESCRIPTION: "RESET_EPRESCRIPTION",
}

export const patient = {
  SAVE_PATIENT: "SAVE_PATIENT",
  RESET_PATIENT: "RESET_PATIENT",
}

export const doctorEnrollment = {
  SAVE_DOCTOR_ENROLLMENT: "SAVE_DOCTOR_ENROLLMENT",
  RESET_DOCTOR_ENROLLMENT: "RESET_DOCTOR_ENROLLMENT",
  SAVE_PRC_ID_FRONT: "SAVE_PRC_ID_FRONT",
  SAVE_PRC_ID_BACK: "SAVE_PRC_ID_BACK",
}

export const consentAndAuth = {
  SAVE_CONSENT_AND_AUTH: "SAVE_CONSENT_AND_AUTH",
}

export const documents = {
  SAVE_DOCUMENT: "SAVE_DOCUMENT",
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  RESET_DOCUMENTS: "RESET_DOCUMENTS",
}

export const epharmacy = {
  SAVE_DELIVERY_DETAILS: "SAVE_DELIVERY_DETAILS",
  RESET_DELIVERY_DETAILS: "RESET_DELIVERY_DETAILS",
}

export const toast = {
  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
}

export const RESET_STATE = "RESET_STATE"

export let AppReducer = (state, action) => {
  switch (action.type) {
    case modal.SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case modal.HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }

    case eprescription.SAVE_MEDICINES:
      return {
        ...state,
        eprescription: { ...state.eprescription, medicines: action.payload },
      }

    case eprescription.SAVE_EPRESCRIPTION:
      return {
        ...state,
        eprescription: { ...state.eprescription, ...action.payload },
      }

    case eprescription.RESET_EPRESCRIPTION:
      return {
        ...state,
        eprescription: { ...initialState.eprescription, ...action.payload },
      }

    case patient.SAVE_PATIENT:
      return {
        ...state,
        patient: { ...state.patient, ...action.payload },
      }

    case patient.RESET_PATIENT:
      return {
        ...state,
        patient: { ...initialState.patient },
      }

    case doctorEnrollment.SAVE_DOCTOR_ENROLLMENT:
      return {
        ...state,
        doctorEnrollment: { ...state.doctorEnrollment, ...action.payload },
      }

    case doctorEnrollment.RESET_DOCTOR_ENROLLMENT:
      return {
        ...state,
        doctorEnrollment: { ...initialState.doctorEnrollment },
      }
    case doctorEnrollment.SAVE_PRC_ID_FRONT:
      return {
        ...state,
        doctorEnrollment: {
          ...state.doctorEnrollment,
          prcId: {
            ...state.doctorEnrollment.prcId,
            front: { ...action.payload },
          },
        },
      }
    case doctorEnrollment.SAVE_PRC_ID_BACK:
      return {
        ...state,
        doctorEnrollment: {
          ...state.doctorEnrollment,
          prcId: {
            ...state.doctorEnrollment.prcId,
            back: { ...action.payload },
          },
        },
      }

    case consentAndAuth.SAVE_CONSENT_AND_AUTH:
      return {
        ...state,
        consentAndAuth: action.payload,
      }

    case documents.SAVE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      }

    case documents.SAVE_DOCUMENTS:
      return {
        ...state,
        documents: [...action.payload],
      }

    case documents.RESET_DOCUMENTS:
      return {
        ...state,
        documents: [],
      }

    case epharmacy.SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }
    case epharmacy.RESET_DELIVERY_DETAILS:
      return {
        ...state,
        epharmacy: { ...initialState.epharmacy },
      }

    case toast.SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case toast.HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case RESET_STATE:
      return { ...initialState }

    default:
      return { ...initialState, ...state }
  }
}
