import React, { useRef, useEffect, useState } from "react"
import classNames from "classnames"
import { Field, ErrorMessage, useField } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

/**
 ** Input field with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'placeholder': String,
 **               'label': String,
 **               'type': String,
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormInput = fieldProps => {
  const [showPassword, setShowPassword] = useState(false)
  const [fieldType, setFieldType] = useState(fieldProps.type)
  const PASSWORD_TYPE = "password"
  const TEXT_TYPE = "text"
  const fieldRef = useRef(null)
  let maxLength = fieldProps.maxLength
  if (!maxLength) maxLength = 50

  const handleScrollCallback = () => {
    fieldRef.current.focus()
    fieldRef.current.scrollIntoView({ block: "center" })
  }

  useEffect(() => {
    if (fieldProps.isFollowUpQuestion) handleScrollCallback()
  }, [fieldProps.isFollowUpQuestion])

  //* Function that prevents alpha and symbols
  //* if the fieldProps.type is number.
  //* This also prevents the user to input characters more than
  //* fieldProps.max (if defined).
  const handleOnKeyPress = event => {
    const isTypeNumber = fieldProps.type === "number"

    if (fieldProps.isNumeric && isNaN(event.key)) {
      event.preventDefault()
    } else if (isTypeNumber) {
      if (
        (event.key !== "." && isNaN(event.key)) ||
        (maxLength && event.target.value.toString().length >= maxLength)
      )
        event.preventDefault()
    }
    if (fieldProps.onKeyPress) fieldProps.onKeyPress(event)
  }

  // We're accessing the useField props below so we can validate forms inline, on touch
  // Source: https://jaredpalmer.com/formik/docs/api/useField#usefieldname-string-fieldattributes-fieldinputprops-fieldmetaprops-fieldhelperprops
  const [, meta] = useField(fieldProps.name)

  const handleShowHidePassword = () => {
    // if current state is true/show, we need to make it false/hide
    if (showPassword) setFieldType(PASSWORD_TYPE)
    else setFieldType(TEXT_TYPE)

    setShowPassword(!showPassword)
  }

  return (
    <div className="mb-2">
      <label className={classNames("label has-text-weight-normal is-size-5")}>
        {!!fieldProps.labelIcon && (
          <span className={`icon has-text-${fieldProps.labelIconColor}`}>
            {fieldProps.labelIcon}
          </span>
        )}
        {fieldProps.label}
        {!fieldProps.isRequired && !fieldProps.hideOptional && (
          <span className="has-text-grey is-italic"> (Optional)</span>
        )}
        {!!fieldProps.helper && (
          <span
            className={classNames(
              "help has-text-weight-normal is-size-6",
              fieldProps.helperClassName
            )}
          >
            {fieldProps.helper}
          </span>
        )}
        {/* {!!fieldProps.helper && (
          <div className="is-size-7">{fieldProps.helper}</div>
        )} */}
      </label>
      <div
        className={classNames("field mb-0", {
          "has-addons has-addons-right":
            fieldProps.hasAddons || fieldProps.type === PASSWORD_TYPE,
        })}
      >
        {fieldProps.hasAddons && fieldProps.addonLeft && (
          <div className="control">{fieldProps.addonLeft}</div>
        )}
        <div
          className={`control ${
            fieldProps.hasAddons || fieldProps.type === PASSWORD_TYPE
              ? "is-expanded"
              : ""
          }`}
        >
          <Field {...fieldProps}>
            {({ field }) => (
              <input
                {...fieldProps}
                {...field}
                type={fieldType}
                ref={fieldRef}
                maxLength={maxLength}
                className={classNames(
                  "input",
                  {
                    "is-success":
                      meta?.touched && !meta?.error && fieldProps.isRequired,
                    "is-danger": meta?.touched && meta?.error,
                  },
                  fieldProps.className
                )}
                onKeyPress={handleOnKeyPress}
                onChange={event => {
                  const isTypeNumber = fieldProps.type === "number"
                  if (isTypeNumber) {
                    if (parseFloat(event.target.value) >= fieldProps.max)
                      event.target.value = fieldProps.max
                    if (parseFloat(event.target.value) <= fieldProps.min)
                      event.target.value = fieldProps.min
                  }
                  if (fieldProps.handleOnKeyDown) {
                    fieldProps.handleOnKeyDown(event)
                  }
                  field.onChange(event)
                  if (fieldProps?.onChange) {
                    fieldProps.onChange(event)
                  }
                }}
              ></input>
            )}
          </Field>
        </div>
        {fieldProps.hasAddons && fieldProps.addonRight && (
          <div className="control">{fieldProps.addonRight}</div>
        )}
        {fieldProps.type === PASSWORD_TYPE ? (
          <div className="control">
            <button
              className="button"
              type="button"
              onClick={handleShowHidePassword}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        ) : null}
      </div>
      <p className="help mt-0 mb-1 is-danger">
        <ErrorMessage name={fieldProps.name} />
      </p>
    </div>
  )
}

export default React.memo(FormInput)
