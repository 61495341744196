import React, { useReducer, useEffect } from "react"
import { AppReducer } from "./AppReducer"
import { initialState } from "./utils/initialState"
import Modal from "elements/Modal"
import Toast from "layout/Toast"
import ModalConfirmation from "elements/ModalConfirmation"
import { decrypt } from "../components/Auth/services/crypto"

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  useEffect(() => {
    const VALID_USER_TYPES = ["Doctor", "Patient"]
    let key = Object.keys(sessionStorage).find(
      key => decrypt({ encryptedData: key }) === "userType"
    )
    let userType = ""

    if (key) userType = decrypt({ encryptedData: sessionStorage.getItem(key) })

    if (!VALID_USER_TYPES.includes(userType)) {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Welcome",
          isCard: true,
          disableCloseModal: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: <ModalConfirmation />,
        },
      })
    }
  }, [])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state?.modal?.isActive}
        isFullheight={state?.modal?.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state?.modal?.heading}
        modalHeaderClass={state?.modal?.headerClass}
        modalBackground={state?.modal?.background}
        hideCloseButton={state?.modal?.hideCloseButton}
        isCard={state?.modal?.isCard}
        disableCloseModal={state?.modal?.disableCloseModal}
      >
        {state?.modal?.content}
      </Modal>

      <Toast
        isActive={state?.toast?.isActive}
        message={state?.toast?.message}
        color={state?.toast?.color}
      />
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
