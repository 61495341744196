import React from "react"

import FormInput from "../FormInput"
import FormCheckbox from "../FormCheckbox"
import FormRadio from "../FormRadio"
import FormSelect from "../FormSelect"
import FormDate from "../FormDate"

// const handleChange = ({ setFieldValue, followUpQuestions }) => {
//   followUpQuestions.map((followUpQuestion) => {
//     setFieldValue(followUpQuestion, [])
//   })
// }

export const generateFormField = config => {
  let { formField, formFields, values, setFieldValue, onChange } = config
  switch (formField?.type) {
    case "text":
    case "number":
      return (
        <FormInput
          type={formField?.type === "text" ? "" : formField?.type}
          isFollowUpQuestion
          {...formField}
        />
      )
    case "date":
      return (
        <FormDate
          values={values[formField?.name]}
          onChange={onChange}
          {...formField}
        />
      )
    case "checkbox":
      return (
        <FormCheckbox
          values={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          // onChange={(event) => {
          //   if (setFieldValue)
          //     handleChange({
          //       setFieldValue,
          //       followUpQuestions: formField?.followUpQuestions,
          //     })
          // }}
          {...formField}
        />
      )
    case "radio":
      return (
        <FormRadio
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          // onChange={() =>
          //   setFieldValue &&
          //   handleChange({
          //     setFieldValue,
          //     followUpQuestions: formField?.followUpQuestions,
          //   })
          // }
          {...formField}
        />
      )
    case "select":
      return <FormSelect values={values} {...formField} />
    default:
      return null
  }
}

export const getFollowUpQuestionData = config => {
  let { followUpQuestion, formFields } = config
  let followUpData = formFields.filter(formField => {
    return followUpQuestion === formField.name
  })

  if (followUpData.length > 0) return followUpData[0]
  else return {}
}
