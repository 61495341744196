import React from "react"

const Brand = ({ type = "complete", ...props }) => {
  switch (type) {
    case "brand":
      return (
        <span className={props.className}>
          Ozempic
          <small>
            <sup>®</sup>
          </small>{" "}
        </span>
      )
    case "generic":
      return <span className={props.className}>Semaglutide</span>
    case "genericBrand":
      return (
        <span className={props.className}>
          Semaglutide (Ozempic
          <small>
            <sup>®</sup>
          </small>
          ){" "}
        </span>
      )
    default:
      return (
        <span className={props.className}>
          Semaglutide (Ozempic
          <small>
            <sup>®</sup>
          </small>
          )
        </span>
      )
  }
}

const ProgramName = ({ ...props }) => {
  return (
    <span className={props.className}>
      <Brand /> Early Experience Program
    </span>
  )
}

export { Brand, ProgramName }
