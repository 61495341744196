import React, { useRef } from "react"
import { Field, FieldArray, ErrorMessage } from "formik"
import classNames from "classnames"
import { isEqual } from "lodash"

import styles from "../utils/elements.module.scss"

/**
 ** Checkbox group linked in formik.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'values': Object [],
 **               'label': String,
 **               'options': Object [],
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormCheckbox = ({
  name,
  options,
  values,
  value = [],
  onChange,
  title,
  disabled,
  isRequired,
  hideOptional,
  helper,
  className,
  disabledOptions,
}) => {
  const fieldRef = useRef(null)
  const fieldValues = values || value

  // Custom handleChange of every checkbox in the group
  //
  // Note: Curried function: need the form from the Field and option (text from values)
  //       to return then (event) => { ... }
  const handleChange = (form, option, index) => event => {
    const { setFieldValue } = form
    let newValue = [...fieldValues]
    if (event.target.checked) newValue.push(option)
    else newValue = newValue.filter(element => element !== option)

    setFieldValue(name, newValue)
    if (onChange) onChange(event)
  }

  const CheckBox = ({ form, option, index }) => (
    <span>
      <input
        className="is-checkradio"
        type="checkbox"
        checked={fieldValues.find(element => isEqual(element, option))}
        onChange={handleChange(form, option)}
        name={`${name}[${index}]`}
        id={`${name}[${index}]`}
        disabled={
          disabledOptions?.find(element => element === option) || disabled
        }
      />
      <label
        className={classNames(
          "is-size-5 checkbox-label",
          styles["form__checkboxLabel"]
        )}
        for={`${name}[${index}]`}
      >
        {option}
      </label>
    </span>
  )

  return (
    <div className={classNames("mb-2", className || "")}>
      {title && (
        <label
          className={classNames("label is-size-5 has-text-weight-normal")}
          ref={fieldRef}
        >
          {title}{" "}
          {!isRequired && !hideOptional && (
            <span className="has-text-grey is-italic"> (Optional)</span>
          )}
        </label>
      )}
      {!!helper && (
        <p className={classNames("has-text-weight-normal is-size-6 mb-1")}>
          {helper}
        </p>
      )}
      <FieldArray name={name}>
        {() =>
          options.map((option, index) => (
            <div key={index} className="mb-1">
              <Field>
                {({ form }) => (
                  <CheckBox form={form} option={option} index={index} />
                )}
              </Field>
            </div>
          ))
        }
      </FieldArray>
      <p className="help is-danger mt-0 mb-1">
        <ErrorMessage name={name} />
      </p>
    </div>
  )
}

export default FormCheckbox
