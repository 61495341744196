import * as Yup from "yup"

const yearNow = new Date(Date.now()).getFullYear()

export const doctorEnrollment = Yup.object().shape({
  lastName: Yup.string().required("This field is required"),
  firstName: Yup.string().required("This field is required"),
  middleInitial: Yup.string(),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^[0-9]*$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string().email("Please input a valid email"),
  prcLicenseNumber: Yup.string()
    .min(5, "Please follow this format: 0012345")
    .max(7, "Please follow this format: 0012345")
    .matches(/^[0-9]*$/, "Please follow this format: 0012345")
    .required("This field is required"),
  primaryClinic: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    clinicHours: Yup.string().required("This field is required"),
    clinicContactNumber: Yup.string()
      .min(8, "Please input a valid landline in this format: 88881234")
      .max(11, "Please input a valid landline in this format: 88881234")
      .matches(
        /^[0-9]*$/,
        "Please input a valid landline in this format: 88881234"
      ),
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    province: Yup.string().required("This field is required"),
  }),
  secondaryClinic: Yup.object().shape({
    name: Yup.string(),
    clinicHours: Yup.string(),
    clinicContactNumber: Yup.string()
      .min(8, "Please input a valid landline in this format: 88881234")
      .max(11, "Please input a valid landline in this format: 88881234")
      .matches(
        /^[0-9]*$/,
        "Please input a valid landline in this format: 88881234"
      ),
    streetAddress: Yup.string(),
    city: Yup.string(),
    province: Yup.string(),
  }),
  termsAndConditions: Yup.array().min(1, "This field is required"),
})

export const patientEnrollment = Yup.object().shape({
  lastName: Yup.string().required("This field is required"),
  firstName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^[0-9]*$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string().email("Please input a valid email"),
  birthday: Yup.object({
    month: Yup.string().required("This field is required"),
    date: Yup.string().required("This field is required"),
    year: Yup.number()
      .min(1900, "Please input a valid year")
      .max(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  streetAddress: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  province: Yup.string().required("This field is required"),
  secondaryContact: Yup.object({
    fullName: Yup.string().required("This field is required"),
    mobileNumber: Yup.string()
      .min(11, "Please input a valid mobile number in this format: 09XXXXXXXXX")
      .max(11, "Please input a valid mobile number in this format: 09XXXXXXXXX")
      .matches(
        /^[0-9]*$/,
        "Please input a valid mobile number in this format: 09XXXXXXXXX"
      )
      .required("This field is required"),
  }),
  doctorFullName: Yup.string().required("This field is required"),
  latestRx: Yup.object({
    month: Yup.string().required("This field is required"),
    date: Yup.string().required("This field is required"),
    year: Yup.number()
      .min(1900, "Please input a valid year")
      .max(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  lastCheckup: Yup.object({
    month: Yup.string(),
    date: Yup.string(),
    year: Yup.number()
      .min(1900, "Please input a valid year")
      .max(yearNow, "Please input a valid year"),
  }),
  lastConfinement: Yup.object({
    month: Yup.string(),
    date: Yup.string(),
    year: Yup.number()
      .min(1900, "Please input a valid year")
      .max(yearNow, "Please input a valid year"),
  }),
  termsAndConditions: Yup.array().min(1, "This field is required"),

  preferredCallDay: Yup.array().min(1, "Please check at least one item"),
  preferredCallTime: Yup.array().min(1, "Please check at least one item"),
})

export const contactUs = Yup.object().shape({
  fullName: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  subject: Yup.string().required("This field is required"),
  emailBody: Yup.string().required("This field is required"),
})
