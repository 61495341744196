// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complete-js": () => import("./../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-doctor-js": () => import("./../src/pages/doctor.js" /* webpackChunkName: "component---src-pages-doctor-js" */),
  "component---src-pages-enroll-doctor-js": () => import("./../src/pages/enroll-doctor.js" /* webpackChunkName: "component---src-pages-enroll-doctor-js" */),
  "component---src-pages-enroll-patient-js": () => import("./../src/pages/enroll-patient.js" /* webpackChunkName: "component---src-pages-enroll-patient-js" */),
  "component---src-pages-epharmacy-js": () => import("./../src/pages/epharmacy.js" /* webpackChunkName: "component---src-pages-epharmacy-js" */),
  "component---src-pages-forgot-password-js": () => import("./../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issue-erx-js": () => import("./../src/pages/issue-erx.js" /* webpackChunkName: "component---src-pages-issue-erx-js" */),
  "component---src-pages-new-js": () => import("./../src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-patient-js": () => import("./../src/pages/patient.js" /* webpackChunkName: "component---src-pages-patient-js" */),
  "component---src-pages-patients-add-js": () => import("./../src/pages/patients/add.js" /* webpackChunkName: "component---src-pages-patients-add-js" */),
  "component---src-pages-patients-edit-js": () => import("./../src/pages/patients/edit.js" /* webpackChunkName: "component---src-pages-patients-edit-js" */),
  "component---src-pages-patients-index-js": () => import("./../src/pages/patients/index.js" /* webpackChunkName: "component---src-pages-patients-index-js" */),
  "component---src-pages-patients-view-js": () => import("./../src/pages/patients/view.js" /* webpackChunkName: "component---src-pages-patients-view-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-sign-in-js": () => import("./../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-out-js": () => import("./../src/pages/sign-out.js" /* webpackChunkName: "component---src-pages-sign-out-js" */),
  "component---src-pages-upload-js": () => import("./../src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */),
  "component---src-pages-view-js": () => import("./../src/pages/view.js" /* webpackChunkName: "component---src-pages-view-js" */),
  "component---src-pages-help-center-mdx": () => import("./../src/pages/help-center.mdx" /* webpackChunkName: "component---src-pages-help-center-mdx" */),
  "component---src-pages-patient-consent-form-mdx": () => import("./../src/pages/patient-consent-form.mdx" /* webpackChunkName: "component---src-pages-patient-consent-form-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-terms-and-conditions-mdx": () => import("./../src/pages/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mdx" */)
}

