import * as Yup from "yup"

export const initialValues = {
  initialModalConfirmation: "",
}

export const modalOptions = [
  "I am a health care professional.",
  "I was prescribed with Semaglutide (Ozempic®) by my doctor.",
]

export const validationSchema = Yup.object().shape({
  initialModalConfirmation: Yup.string().required("This field is required"),
})
