import React from "react"
import classNames from "classnames"

const DeliveryDisclaimerMessage = ({ size }) => {
  return (
    <section class="mt-1">
      <span
        className={classNames(`has-text-weight-bold is-size-${size || 5} `)}
      >
        Deliveries in NCR may start as early as February 22. Due to logistics
        constraints surrounding COVID-19, deliveries outside of NCR will start
        no later than March 1. You will be contacted directly in case deliveries
        are possible earlier.
      </span>
      <p className={classNames(`mt-1 is-size-${size || 5} `)}>
        For enrollments after February 22:
      </p>
      <ul className={classNames(`is-size-${size || 5} `)}>
        <li>
          Deliveries in NCR will be served within 1-3 working days of enrollment
          verification.
        </li>
        <li>
          Deliveries in major cities outside of NCR (Batangas, Bulacan, Cavite,
          Laguna, Nueva Ecija, Pampanga, Pangasinan, Rizal, Tarlac, Bacolod,
          Cebu, Iloilo, Davao, and Cagayan de Oro) will be served within 5-7
          working days of enrollment verification.
        </li>
        <li>
          Delivery schedule for other locations may vary. MedGrocer and our
          partners will update you on actual delivery dates.
        </li>
      </ul>
    </section>
  )
}

export default DeliveryDisclaimerMessage
