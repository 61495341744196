import React, { Fragment, useContext, useState } from "react"
import { Form, Formik } from "formik"
import classNames from "classnames"
import FormRadio from "elements/Form/FormRadio"
import Button from "elements/Button"

import {
  initialValues,
  modalOptions,
  validationSchema,
} from "../../context/utils/modalValidation"
import { AppContext } from "../../context/AppContext"
import { encrypt } from "../Auth/services/crypto"

const ModalConfirmation = () => {
  const [loading, setLoading] = useState(false)
  const { dispatch } = useContext(AppContext)

  const userTypeKey = encrypt({ data: "userType" })

  const handleClick = values => {
    setLoading(true)
    let answer =
      values?.initialModalConfirmation === "I am a health care professional."
        ? encrypt({ data: "Doctor" })
        : encrypt({ data: "Patient" })
    sessionStorage.setItem(`${userTypeKey}`, answer)
    dispatch({ type: "HIDE_MODAL" })
  }

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleClick}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <div className="has-text-left px-1 px-0-mobile">
              <FormRadio
                name="initialModalConfirmation"
                value={values?.initialModalConfirmation}
                options={modalOptions}
                isRequired
                title="To view the website, please confirm either of the following:"
              />
            </div>
            <Button
              type="submit"
              color="primary"
              className={classNames({ "is-loading": loading })}
            >
              Confirm
            </Button>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default ModalConfirmation
