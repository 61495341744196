import aes from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"
import { GATSBY_AES_KEY } from "gatsby-env-variables"

export const encrypt = ({ data }) => {
  return aes.encrypt(data, GATSBY_AES_KEY).toString()
}

export const decrypt = ({ encryptedData }) => {
  return aes.decrypt(encryptedData, GATSBY_AES_KEY).toString(Utf8)
}
